import {
  APPLIANCE_ONLINE_RETAILER_ID,
  getStateStockLevel,
} from '../appliances-online-state-inventory'
import {
  type Advert,
  type AdvertVariant,
  type AoStock,
  type Maybe,
  type Product,
} from '@/lib/generated/graphql'
import {
  type Advert as PlatformAdvert,
  type Variant as PlatformVariant,
} from '@/lib/generated/platform-graphql'

export const getMaxBuyableQuantity = (
  variant: Maybe<AdvertVariant> | undefined,
  advert: Maybe<Advert> | undefined,
  product: Maybe<Product> | undefined,
  postcode: Maybe<string> | undefined,
) => {
  if (product?.product_retailer?.retailer_id !== APPLIANCE_ONLINE_RETAILER_ID) {
    return variant?.infiniteQuantity ? 100_000 : (variant?.countOnHand ?? 0)
  }

  return getStateStockLevel(
    postcode ?? undefined,
    product?.product_retailer?.retailer_id,
    (advert?.aoStockCodes ?? []) as AoStock[],
  )
}

export const getMaxBuyableQuantityPlatform = (
  retailerId: string,
  variant?: PlatformVariant | null,
  advert?: PlatformAdvert | null,
  postcode?: string | null,
) => {
  if (retailerId !== APPLIANCE_ONLINE_RETAILER_ID) {
    return variant?.availableQuantity ?? 0
  }

  return getStateStockLevel(
    postcode ?? undefined,
    retailerId,
    (advert?.aoStock ?? []) as AoStock[],
  )
}
