/* eslint-disable import/namespace */
import { isNullOrEmpty } from '@/utils/string-extensions'
import { hostURL } from '@/utils/functions/url-utils'
import {
  type ValidateCartDeliveryQueryVariables,
  type ValidateCartDeliveryQuery,
  type UpdateCartMetadataMutationVariables,
  type Cart,
} from '../generated/graphql'
import { deleteBasketClient } from './basket-cookie-client'
import * as Sentry from '@sentry/nextjs'

export const getCart = async (props?: { cartId?: string }) => {
  const { cartId } = props ?? {}

  try {
    const res = await fetch(
      `${hostURL()}/api/cart${isNullOrEmpty(cartId) ? '' : '/' + cartId}`,
    )

    const cart = (await res.json()) as Cart | false

    if (!isNullOrEmpty(cartId) && cart === false) {
      deleteBasketClient()
      return false
    }

    return cart
  } catch (error) {
    deleteBasketClient()
    Sentry.captureException(error)
  }
}

export const getPlatformCart = async (props?: { cartId?: string }) => {
  const { cartId } = props ?? {}

  try {
    const res = await fetch(
      `${hostURL()}/api/cart/platform${isNullOrEmpty(cartId) ? '' : '/' + cartId}`,
    )

    const cart = await res.json()

    if (!isNullOrEmpty(cartId) && cart === false) {
      deleteBasketClient()
      return false
    }

    return cart
  } catch (error) {
    deleteBasketClient()
    Sentry.captureException(error)
  }
}

export const validateCartDelivery = async (
  validateDelivery?: ValidateCartDeliveryQueryVariables,
) => {
  try {
    const res = await fetch(`${hostURL()}/api/cart/validate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(validateDelivery),
    })

    return (await res?.json()) as ValidateCartDeliveryQuery
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const updateCartMetadata = async (
  cartMetadata?: UpdateCartMetadataMutationVariables,
) => {
  try {
    const res = await fetch(`${hostURL()}/api/cart/update-cart-metadata`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cartMetadata),
    })

    if (res.status !== 200) {
      // eslint-disable-next-line no-console
      Sentry.addBreadcrumb({
        level: 'error',
        message: 'Error updating cart metadata',
        data: {
          res,
          cartMetadata,
        },
      })
      Sentry.captureException('Error updating cart metadata')
      return null
    }
    return null
  } catch (error) {
    Sentry.captureException(error)
  }
}
