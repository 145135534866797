/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable unicorn/no-useless-undefined */
/* eslint-disable @typescript-eslint/no-unused-vars */
'use client'

import { upsertNewCartClient } from '@/lib/data/cart-queries'
import { getPostcodeClient } from '@/lib/data/postcode-client'
import {
  type AoStock,
  type Cart,
  CartItemStatus,
  type Product,
} from '@/lib/generated/graphql'
import { routeCalculatorRelative } from '@/lib/route-calculator'
import { usePathname, useRouter } from 'next/navigation'
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSessionStorage } from 'react-use'
import { useUserActionError } from '../utility/user-error/user-action-error-context'
import {
  type CartLine,
  type NewCartWithPayment,
  type UICart,
  type UserCart,
} from './cart-types'

import {
  deleteBasketClient,
  setPlatformBasketClient,
} from '@/lib/data/basket-cookie-client'
import {
  LasooCartAction,
  type LasooCartCustomerDetails,
} from '@/lib/generated/platform-graphql'
import { mapUserCartToInput } from './cart-actions/map-platform-cart-to-input'
import { mapUserCartToUi } from './cart-actions/map-platform-cart-to-ui'
import { addToCart } from './cart-mutations/add-to-cart'
import { updateCartItemQuantity } from './cart-mutations/update-cart'
import { updateCartCoupon } from './cart-mutations/update-cart-coupon'

import { getPlatformCart } from '@/lib/data/get-cart-queries'
import { Hub } from '@aws-amplify/core'

interface CartContextType {
  cart?: Cart
  uiCart?: UICart
  setCart: (newCart?: UserCart) => void
  couponCode?: string | null
  setCouponCode: (couponCode: string | null) => Promise<boolean | undefined>
  cartCount?: number
  couponError?: boolean
  setCartCount: (cartCount: number) => void
  cartValid?: boolean
  cartLoading: boolean
  userCart?: UserCart
  clearCart: () => void
  updateCartQuantity: (
    advertId: string,
    variantId: string,
    quantity: number,
  ) => Promise<void>
  upsertToCart: (
    product: Product,
    variantId?: string | null,
    quantity?: number,
    aoStockCodes?: AoStock[],
  ) => Promise<void>
  lockUpsertCart: (lock: boolean) => void
  addCartItemValidator: (validator: () => boolean) => void
  validateCartItems: () => boolean
  setNewCart: (newCart: UserCart) => void
  setCustomerData: (customerData: LasooCartCustomerDetails) => void
}

export const CartContextSSR = createContext<CartContextType>({
  cartCount: 0,
  cartLoading: false,
  clearCart: () => {
    throw new Error('clearCart not implemented')
  },
  setCart: () => {
    throw new Error('setCart not implemented')
  },
  setCartCount: () => {
    throw new Error('setCartCount not implemented')
  },
  updateCartQuantity: (
    advertId: string,
    variantId: string,
    quantity: number,
  ) => {
    throw new Error('updateCartQuantity not implemented')
  },
  upsertToCart: (
    product: Product,
    variantId?: string | null,
    quantity?: number,
    aoStockCodes?: AoStock[],
  ) => {
    throw new Error('upsertToCart not implemented')
  },
  lockUpsertCart: (lock: boolean) =>
    new Error('lockUpsertCart not implemented'),
  setCouponCode: () => {
    throw new Error('setCouponCode not implemented')
  },
  addCartItemValidator: () => {},
  validateCartItems: () => {
    return false
  },
  setNewCart: (newCart: UserCart) => {
    throw new Error('setNewCart not implemented')
  },
  setCustomerData: () => {
    throw new Error('setCustomerData not implemented')
  },
})

export const useCart = () => useContext(CartContextSSR)

export const CartProviderSSR = ({
  children,
  newCart,
}: PropsWithChildren<{ newCart?: NewCartWithPayment }>) => {
  const path = usePathname()
  const router = useRouter()
  const { setUserActionError } = useUserActionError()

  const [upsertCartLocked, lockUpsertCart] = useSessionStorage(
    'upsertCartLocked',
    false,
  )

  /**
   * @question
   * Since setCouponError was never passed down or called anywhere,
   * wouldn't couponError always be undefined?
   */
  const [couponError, setCouponError] = useState<boolean | undefined>(undefined)
  const [userCart, setUserCart] = useState<UserCart | undefined>(newCart)

  const [couponCode, setCouponCodeInternal] = useState<string | null>(() => {
    return newCart?.coupon ?? null
  })

  const [uiCart, setUICart] = useState<UICart | undefined>(() => {
    return newCart == null
      ? mapUserCartToUi(undefined, getPostcodeClient())
      : mapUserCartToUi(newCart, getPostcodeClient())
  })

  const [cartValid, setCartValid] = useState<boolean>(true)
  const [cartLoading, setCartLoading] = useState<boolean>(false)
  const [cartCount, setCartCount] = useState<number | undefined>(() => {
    return newCart?.totalQuantity ?? 0
  })

  const [cartItemValidators, addCartItemValidators] = useState<
    Array<() => boolean>
  >([])

  const clearCart = useCallback(() => {
    setUserCart(undefined)
    setUICart(undefined)
    setCartCount(0)
    setCartLoading(false)
    deleteBasketClient()
  }, [])

  const setCart = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (newCart?: UserCart) => {
      const cartCount = newCart?.totalQuantity ?? 0
      setPlatformBasketClient(newCart?.cartId as string)

      setCartCount(cartCount)
      const postcode = getPostcodeClient()

      const mappedUICartFromUserCart: UICart | undefined =
        newCart == null ? undefined : mapUserCartToUi(newCart, postcode)

      if (newCart != null) {
        setUserCart(newCart)
        setCouponCodeInternal(newCart.coupon ?? null)
        setUICart(mappedUICartFromUserCart)
      }

      setCartLoading(false)
    },
    [],
  )

  useEffect(() => {
    Hub.listen('auth', async (data) => {
      // eslint-disable-next-line sonarjs/no-small-switch
      switch (data.payload.event) {
        case 'signedIn': {
          const platformCart = await getPlatformCart()
          setCart(platformCart)
          break
        }
        case 'signedOut':
          clearCart()
          break
      }
    })
  }, [clearCart, router, setCart])

  const setCustomerData = useCallback(
    async (customerDetails: LasooCartCustomerDetails) => {
      if (upsertCartLocked) {
        return undefined
      }

      const newCart = {
        ...userCart,
        customerDetails,
      } as UserCart
      const cartInput = mapUserCartToInput(newCart)
      const updatedCart = await upsertNewCartClient(
        cartInput,
        LasooCartAction.UpdateCustomerDetails,
      )
      if (updatedCart !== false && updatedCart?.__typename === 'LasooCart') {
        setCart(updatedCart)
      }
    },
    [setCart, userCart, upsertCartLocked],
  )

  const setCouponCode = useCallback(
    async (coupon: string | null) => {
      if (upsertCartLocked) {
        return undefined
      }
      return await updateCartCoupon(setCart, coupon, userCart)
    },
    [setCart, userCart, upsertCartLocked],
  )

  const isCartValidInternal = useCallback(
    (uiCart: UICart, triggerSetUserAction: boolean = true): boolean => {
      const errorLines: CartLine[] = []
      if (uiCart?.lineGroups === undefined) return false
      for (const retailers of uiCart.lineGroups) {
        if (retailers.lines === undefined) return false
        for (const cartLine of retailers.lines) {
          if (cartLine.status !== CartItemStatus.Valid) {
            errorLines.push(cartLine)
          }
        }
      }
      if (errorLines.length === 0) return true

      const errorMessage = `${errorLines.length} item(s) from your cart are no longer available. Please check cart and try again.`

      if (triggerSetUserAction) {
        setUserActionError({
          error_title: 'Invalid items on Cart',
          error_message: errorMessage,
          buttonLabel: 'Check Cart',
          buttonAction: () => {
            router.push(routeCalculatorRelative.cart)
            router.refresh()
          },
        })
      }

      return false
    },
    [router, setUserActionError],
  )

  useEffect(() => {
    setCartValid(
      isCartValidInternal(
        uiCart as UICart,
        path !== routeCalculatorRelative.cart,
      ),
    )
  }, [uiCart, path, isCartValidInternal])

  const updateCartQuantity = useCallback(
    async (advertId: string, variantId: string, quantity: number) => {
      if (upsertCartLocked) {
        return undefined
      }
      setCartLoading(true)

      await updateCartItemQuantity(
        advertId,
        variantId,
        quantity,
        setCart,
        userCart,
      )

      setCartLoading(false)
    },
    [setCart, userCart, upsertCartLocked],
  )

  const upsertToCart = useCallback(
    async (product: Product, variantId?: string | null, quantity?: number) => {
      setCartLoading(true)
      await addToCart(product, setCart, userCart, variantId, quantity)
      setCartLoading(false)
    },
    [userCart, setCart],
  )

  const addCartItemValidator = useCallback((validator: () => boolean) => {
    addCartItemValidators((prev) => [...prev, validator])
  }, [])

  const validateCartItems = useCallback(() => {
    let cartValid = true
    for (const validator of cartItemValidators) {
      cartValid = cartValid && validator()
    }
    return cartValid
  }, [cartItemValidators])

  const value = useMemo(
    () => ({
      setCart,
      cartCount,
      setCartCount,
      upsertToCart,
      cartLoading,
      updateCartQuantity,
      cartValid,
      couponError,
      couponCode,
      setCouponCode,
      uiCart,
      addCartItemValidator,
      validateCartItems,
      clearCart,
      setNewCart: setUserCart,
      userCart,
      setCustomerData,
      lockUpsertCart,
    }),
    [
      setCart,
      cartCount,
      upsertToCart,
      cartLoading,
      updateCartQuantity,
      cartValid,
      couponError,
      couponCode,
      setCouponCode,
      uiCart,
      addCartItemValidator,
      validateCartItems,
      clearCart,
      setUserCart,
      userCart,
      setCustomerData,
      lockUpsertCart,
    ],
  )

  return (
    <CartContextSSR.Provider value={value}>{children}</CartContextSSR.Provider>
  )
}
