/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable import/namespace */
import * as Sentry from '@sentry/nextjs'

import { upsertNewCartClient } from '@/lib/data/cart-queries'
import { type Product } from '@/lib/generated/graphql'

import {
  LasooCartAction,
  type LasooCartInput,
} from '@/lib/generated/platform-graphql'
import { toast } from 'react-toastify'
import { mapUserCartToInput } from '../cart-actions/map-platform-cart-to-input'
import { type UserCart } from '../cart-types'

import { CheckoutNowButton } from '../checkout-now-button'

const upsertNewCart = async (
  product: Product,
  quantity: number,
  userCart?: UserCart,
  variantId?: string | null,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const cartToUpdate: LasooCartInput = mapUserCartToInput(userCart)

  try {
    const existingItemIndex =
      cartToUpdate.items?.findIndex(
        (item) =>
          item?.advertId === product?.product_advertLookUp &&
          item?.variantId === variantId,
      ) ?? -1
    if (
      existingItemIndex > -1 &&
      cartToUpdate.items[existingItemIndex] != null
    ) {
      cartToUpdate.items[existingItemIndex].quantity += quantity
    } else {
      cartToUpdate.items.push({
        productId: Number.parseInt(product?.product_id ?? ''),
        retailerId: product?.product_retailerId as string,
        advertId: product?.product_advertLookUp as string,
        variantId: variantId as string,
        quantity,
      })
    }
    const cartResponse = await upsertNewCartClient(
      cartToUpdate,
      LasooCartAction.AddToCart,
    )
    if (cartResponse == null || cartResponse === false) {
      Sentry.captureException(new Error('Error upserting new cart'))
      return
    }

    return cartResponse
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}

const showErrorToast = () => {
  toast('Error adding cart item', {
    className: 'bg-error text-white',
    position: 'top-center',
  })
}

const showSuccessToast = () => {
  toast(
    <div className='flex w-full flex-row text-white'>
      <div className='text-s m-0 flex shrink-0 basis-1/3 flex-row items-center justify-center px-0 align-middle'>
        Added to Cart
      </div>
      <CheckoutNowButton
        text='Checkout Now'
        size='medium'
        containerClassName='flex flex-auto basis-2/3 justify-center'
        buttonClassName='min-w-[200px] bg-dark bold'
      />
    </div>,
    {
      className: 'bg-success text-white',
      position: 'bottom-center',
      pauseOnHover: true,
    },
  )
}

export const addToCart = async (
  product: Product,
  setCart: (newCart?: UserCart) => void,
  userCart?: UserCart,
  variantId?: string | null,
  quantity?: number,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  // eslint-disable-next-line unicorn/prefer-default-parameters
  const itemQuantity = quantity ?? 1

  if (itemQuantity < 1) {
    showErrorToast()
    return
  }

  const newCart = await upsertNewCart(
    product,
    itemQuantity,
    userCart,
    variantId,
  )

  if (newCart?.__typename === 'LasooCart') {
    setCart(newCart)
    showSuccessToast()
  } else {
    showErrorToast()
  }
}
