/* eslint-disable import/namespace */
import * as Sentry from '@sentry/nextjs'

import { type LasooCartInput } from '@/lib/generated/platform-graphql'

import { upsertCart } from '@/lib/data/cart/upsert'
import { toast } from 'react-toastify'
import { mapUserCartToInput } from '../cart-actions/map-platform-cart-to-input'
import { type UserCart } from '../cart-types'

const updateNewCartQuantity = async (
  advertId: string,
  variantId: string,
  quantity: number,
  userCart?: UserCart,
) => {
  try {
    const cartToUpdate: LasooCartInput = mapUserCartToInput(userCart)

    const existingItemIndex =
      cartToUpdate.items?.findIndex(
        (item) => item?.advertId === advertId && item?.variantId === variantId,
      ) ?? -1

    if (
      existingItemIndex > -1 &&
      cartToUpdate.items[existingItemIndex] != null
    ) {
      // what happens if quantity is <= 0?
      cartToUpdate.items[existingItemIndex].quantity = quantity
    }
    // do we still want to update this if there's no change?
    const cartResponse = await upsertCart({ cart: cartToUpdate })
    if (cartResponse == null || cartResponse === false) {
      Sentry.captureException(new Error('Error upserting new cart'))
      return
    }
    return cartResponse
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}

const showErrorToast = () => {
  toast('Error updating cart item', {
    className: 'bg-red-500 text-white',
    position: 'top-center',
  })
}

export const updateCartItemQuantity = async (
  advertId: string,
  variantId: string,
  quantity: number,
  setCart: (newCart?: UserCart) => void,
  userCart?: UserCart,
) => {
  const newCart = await updateNewCartQuantity(
    advertId,
    variantId,
    quantity,
    userCart,
  )

  if (newCart?.__typename === 'LasooCart') {
    setCart(newCart)
  } else {
    showErrorToast()
  }
}
