/* eslint-disable import/namespace */
import { hostURL } from '@/utils/functions/url-utils'
import {
  type AddItemToCartMutation,
  type AddItemToCartMutationVariables,
} from '../generated/graphql'
import * as Sentry from '@sentry/nextjs'
import {
  type LasooCartInput,
  type LasooCartAction,
} from '../generated/platform-graphql'
import { type UserCart } from '@/components/cart/cart-types'

export const addToCartClient = async (
  addToCart: AddItemToCartMutationVariables,
) => {
  try {
    Sentry.addBreadcrumb({
      category: 'cart',
      message: 'add to cart',
      level: 'info',
      data: addToCart,
    })
    const resp = await fetch(`${hostURL()}/api/cart/add-to-cart`, {
      method: 'POST',
      body: JSON.stringify(addToCart),
    })

    return (await resp.json()) as AddItemToCartMutation['addItemToCart'] | false
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const upsertNewCartClient = async (
  cart: LasooCartInput,
  action: LasooCartAction,
): Promise<UserCart | false> => {
  if (typeof window !== 'undefined') {
    if (window.sessionStorage.getItem('upsertCartLocked') === 'true') {
      return false
    }
    try {
      Sentry.addBreadcrumb({
        category: 'cart',
        message: 'add to new cart',
        level: 'info',
        data: cart,
      })
      const resp = await fetch(`${hostURL()}/api/cart/upsert`, {
        method: 'POST',
        body: JSON.stringify({ cart, action: { action } }),
      })

      return await resp.json()
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}
