'use client'

import './gradient-button.css'

import { Button } from '@/components/library/button'
import { routeCalculatorRelative } from '@/lib/route-calculator'
import { twMerge } from 'tailwind-merge'
import { useCart } from '@/components/cart/cart-context'
import { useRouter } from 'next/navigation'

export interface CheckoutNowButtonProps {
  disabled?: boolean
  isLoading?: boolean
  size?: 'small' | 'medium' | 'large' | 'extraLarge'
  containerClassName?: string
  buttonClassName?: string
  text?: string
}

export const CheckoutNowButton = (props: CheckoutNowButtonProps) => {
  const { cartLoading } = useCart()
  const router = useRouter()

  const isLoading = props.isLoading ?? cartLoading

  return (
    <div className={twMerge('flex items-center', props.containerClassName)}>
      <div className='relative m-1 flex items-center justify-center'>
        <Button
          variant='outlined'
          className={twMerge(
            'focused:bg-dark focused:text-white border-secondary text-white hover:bg-white hover:text-dark active:bg-dark active:text-white font-bold',
            props.buttonClassName,
          )}
          size={props.size ?? 'medium'}
          onClick={(e) => {
            e.preventDefault()
            router.push(routeCalculatorRelative.cart)
            router.refresh()
          }}
        >
          {props?.text ?? 'Checkout Now'}
        </Button>
        {isLoading && (
          <div
            className='absolute inline-block size-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]'
            role='status'
          />
        )}
      </div>
    </div>
  )
}
