/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
/* eslint-disable @typescript-eslint/naming-convention */
import { type AoStock } from '@/lib/generated/graphql'

// Set retailer code.
export const APPLIANCE_ONLINE_RETAILER_ID =
  process.env.APPLIANCES_ONLINE_RETAILER_ID ?? 'APO'

export const stockAvailableInState = (
  postcode: string | undefined,
  retailer_id: string | undefined,
  aoStockCodes?: AoStock[],
  quantity?: number,
): boolean =>
  quantity != null && quantity > 0
    ? getStateStockLevel(postcode, retailer_id, aoStockCodes) >= quantity
    : getStateStockLevel(postcode, retailer_id, aoStockCodes) > 0

export const getStateStockLevel = (
  postcode: string | undefined,
  retailer_id: string | undefined,
  aoStockCodes?: AoStock[],
): number => {
  if (retailer_id === undefined || postcode === undefined) {
    return 0
  }

  const { abbreviation: state } = postcodeToPostalState(postcode)

  return (
    aoStockCodes?.filter(({ key: aoState }) => aoState === state).pop()
      ?.value ?? 0
  )
}

const australianCapitalTerritory = {
  name: 'Australian Capital Territory',
  abbreviation: 'ACT',
}
const newSouthWales = {
  name: 'New South Wales',
  abbreviation: 'NSW',
}
const victoria = {
  name: 'Victoria',
  abbreviation: 'VIC',
}
const queensland = {
  name: 'Queensland',
  abbreviation: 'QLD',
}
const southAustralia = {
  name: 'South Australia',
  abbreviation: 'SA',
}
const westernAustralia = {
  name: 'Western Australia',
  abbreviation: 'WA',
}
const tasmania = {
  name: 'Tasmania',
  abbreviation: 'TAS',
}
const northernTerritory = {
  name: 'Northern Territory',
  abbreviation: 'NT',
}

const postcodeToPostalState = (postcode: string) => {
  if (/^02\d{2}$/.test(postcode)) {
    return australianCapitalTerritory
  }
  if (/^26\d{2}$/.test(postcode) && Number.parseInt(postcode) < 2619) {
    return australianCapitalTerritory
  }
  if (/^29\d{2}$/.test(postcode) && Number.parseInt(postcode) < 2921) {
    return australianCapitalTerritory
  }
  if (/^[12|]\d{3}$/.test(postcode)) {
    return newSouthWales
  }
  if (/^[38|]\d{3}$/.test(postcode)) {
    return victoria
  }
  if (/^[49|]\d{3}$/.test(postcode)) {
    return queensland
  }
  if (/^5\d{3}$/.test(postcode)) {
    return southAustralia
  }
  if (/^6\d{3}$/.test(postcode)) {
    return westernAustralia
  }
  if (/^7\d{3}$/.test(postcode)) {
    return tasmania
  }
  if (/^0[89|]\d{2}$/.test(postcode)) {
    return northernTerritory
  }
  throw new Error('Invalid postcode')
}
