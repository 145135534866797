/* eslint-disable import/namespace */
import * as Sentry from '@sentry/nextjs'

import { upsertNewCartClient } from '@/lib/data/cart-queries'
import {
  LasooCartAction,
  type LasooCartInput,
} from '@/lib/generated/platform-graphql'
import { Zoom, toast } from 'react-toastify'
import { mapUserCartToInput } from '../cart-actions/map-platform-cart-to-input'
import { type UserCart } from '../cart-types'

const updateCouponForUserCart = async (
  couponCode: string | null,
  userCart?: UserCart,
): Promise<{ cart?: UserCart; success: boolean }> => {
  try {
    const cartToUpdate: LasooCartInput = mapUserCartToInput(userCart)
    cartToUpdate.coupon = couponCode

    const cart = await upsertNewCartClient(
      cartToUpdate,
      LasooCartAction.AddCoupon,
    )
    if (cart !== false && cart?.__typename === 'LasooCart') {
      return { cart, success: true }
    }
    Sentry.captureException(new Error('Error upserting user cart'))
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
  return { cart: undefined, success: false }
}

export const updateCartCoupon = async (
  setCart: (newCart?: UserCart) => void,
  couponCode: string | null,
  userCart?: UserCart,
) => {
  // TODO: revalidate this logic for when we use new cart
  if (userCart == null) {
    toast(<div className='text-white'>Cart is empty</div>, {
      className: 'bg-red-500 text-white',
      position: 'bottom-center',
      transition: Zoom,
    })
    return true
  }

  const userCartResult = await updateCouponForUserCart(couponCode, userCart)

  if (userCartResult?.cart?.__typename === 'LasooCart') {
    setCart(userCartResult.cart)
  }

  return userCartResult?.success
}
