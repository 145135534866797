/* eslint-disable unicorn/no-array-reduce */
import {
  type UserCart,
  type CartGroup,
  type CartLine,
  type UICart,
} from '../cart-types'
import { CartItemStatus, RetailerDeliveryMethod } from '@/lib/generated/graphql'

import {
  LasooCartItemStatus,
  RetailerDeliveryMethodEnum,
  type Advert as PlatformAdvert,
} from '@/lib/generated/platform-graphql'
import { getMaxBuyableQuantityPlatform } from '@/utils/functions/cart/get-max-buyable-quantity'

const getAgeCheckFlag: (cart?: UserCart) => boolean = (cart) =>
  cart?.items?.some((item) => item?.retailer?.requireOverAgeCheck === true) ??
  false

const getLineGroups = (
  cart: UserCart,
  postcode?: string | null,
): CartGroup[] => {
  // TODO: seller/retailer information against cart groups

  return (
    cart?.items?.reduce<CartGroup[]>((acc, cartItem) => {
      const retailer = cartItem.retailer
      let group = acc.find((group) => group.id === retailer?.id)

      if (!group) {
        group = {
          id: retailer?.id as string,
          lines: [],
          retailerName: retailer?.displayName as string,
          retailerLogoSrc: retailer?.logoUrl as string,
          retailerLogoBgColor: retailer?.colour as string,
          isCart: false,
          shippingEstimationMessage:
            retailer?.shippingEstimationMessage as string,
        }
        acc.push(group)
      }

      const line: CartLine = {
        id: '', // cartItem?.id as string
        productId: cartItem.productId,
        quantity: cartItem.quantity,
        salePrice:
          cartItem.variant?.salePriceInCents ??
          (cartItem.advert?.salePriceInCents as number),
        price:
          cartItem.variant?.priceInCents ??
          (cartItem.variant?.priceInCents as number),
        productName: cartItem.product?.title as string,
        productImageSrc: cartItem.product?.imageUrl as string,
        advertId: cartItem.advertId,
        variantId: cartItem.variantId,
        available: cartItem.variant?.available ?? false,
        maxQuantity: getMaxBuyableQuantityPlatform(
          cartItem.retailerId,
          cartItem?.variant,
          cartItem?.advert as PlatformAdvert,
          postcode,
        ),
        // TODO: to be wired up
        status: CartItemStatus.Valid, // cartItem.status
        variantLabel: cartItem.variant?.label as string,
        categoryLevel1: cartItem.product?.category?.level1 ?? '',
      }

      group.lines.push(line)

      return acc
    }, []) ?? []
  )
}

export interface DiscountPriceProps {
  productPrice?: number | null
  productDiscountPrice?: number | null
  variantPrice?: number | null
  variantDiscountPrice?: number | null
}

export const mapUserCartToUi = (
  cart: UserCart,
  postcode?: string | null,
): UICart => {
  if (cart == null) {
    return {
      id: null,
      lineGroups: [],
      customerDetails: {},
      total: 0,
      itemsValid: true,
      requireOverAgeCheck: false,
      totalQuantity: 0,
    }
  }
  const requireOverAgeCheck = getAgeCheckFlag(cart)
  return {
    id: cart.cartId,
    lineGroups: getLineGroups(cart, postcode),
    totalQuantity: cart.totalQuantity,
    total: cart.totalCents,
    totalDiscount: cart.discountCents.discountCents,
    couponError: cart.discountCents.message,
    subtotal: cart.subtotalCents,
    couponCode: cart.coupon as string,
    shippingCost: cart.shippingCents,
    deliveryFees: cart.shippingCentsGrouped?.map((shipping) => ({
      deliveryFee: shipping.costInCents,
      retailer: cart.items.find(
        (cart) => cart.retailerId === shipping.retailerId,
      )?.retailer?.displayName,
    })),
    requireOverAgeCheck,
    // TODO add on backend the valid on line items
    itemsValid: cart.items.every(
      (item) => item.status === LasooCartItemStatus.Valid,
    ),
    // TODO add on backend the valid on line items
    postcodeValid: !cart.items.some(
      (item) => item.status === LasooCartItemStatus.UnavailableForPostcode,
    ),
    customerDetails: {
      firstName: cart.customerDetails?.firstName as string,
      surname: cart.customerDetails?.surname as string,
      emailAddress: cart.customerDetails?.email as string,
      billingPhone: cart.customerDetails?.phone as string,
      address: {
        ...cart.customerDetails?.shippingAddress,
        country: cart.customerDetails?.shippingAddress?.country
          ? undefined
          : {
              code: cart.customerDetails?.shippingAddress?.country,
            },
        __typename: 'cartAddress',
      },
      billingAddress: {
        ...cart.customerDetails?.billingAddress,
        country: cart.customerDetails?.billingAddress?.country
          ? undefined
          : {
              code: cart.customerDetails?.billingAddress?.country,
            },
        __typename: 'cartAddress',
      },
      billingSameAsShippingAddress:
        cart.customerDetails?.billingSameAsShippingAddress,
      recipientFirstName: cart.customerDetails?.recipientFirstName,
      recipientSurname: cart.customerDetails?.recipientSurname,
      recipientMobile: cart.customerDetails?.recipientMobile,
      recipientOver18Confirmed: cart.customerDetails?.recipientOver18Confirmed,
    },
    retailerDeliveryMethod: requireOverAgeCheck
      ? getRetailerDeliveryMethod(cart)
      : null,
  }
}

const getRetailerDeliveryMethod: (
  cart?: UserCart,
) => RetailerDeliveryMethod | null = (cart) => {
  const deliveryMethods =
    cart?.items?.map((item) => item?.retailer?.deliveryMethod) ?? []

  if (deliveryMethods.includes(RetailerDeliveryMethodEnum.SameDay2024)) {
    return RetailerDeliveryMethod.SameDay_2024
  }

  if (deliveryMethods.includes(RetailerDeliveryMethodEnum.SameDay2023)) {
    return RetailerDeliveryMethod.SameDay_2023
  }

  return RetailerDeliveryMethod.Standard
}
