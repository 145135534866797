import { type LasooCartInput } from '@/lib/generated/platform-graphql'
import { type UserCart } from '../cart-types'

export const mapUserCartToInput = (cart?: UserCart): LasooCartInput => {
  if (cart == null) {
    return {
      items: [],
    }
  }

  return {
    cartId: cart.cartId,
    coupon: cart.coupon,
    createdAt: cart.createdAt,
    items:
      cart.items?.map((item) => ({
        productId: item.productId,
        advertId: item.advertId,
        variantId: item.variantId,
        retailerId: item.retailerId,
        quantity: item.quantity,
      })) ?? [],
    customerDetails:
      cart.customerDetails == null
        ? undefined
        : {
            ...cart.customerDetails,
          },
  }
}
